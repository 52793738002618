// NL - Consumer
export const heatPumpConsumerNL = {
  id: '27c9fa15-9597-43d2-939b-0c5aa1ecdeb3',
  identifier: 'heat-pump-consumer',
}

// NL - Corporate
export const heatPumpCorporateNL = {
  id: 'de8f70b6-e9c6-42a6-8974-536490c6ccb0',
  identifier: 'heat-pump-corporate',
}

// ES - Consumer
export const heatPumpConsumerES = {
  id: '2880f8b6-675f-4dc4-a9fa-f8067cd39d58',
  identifier: 'heat-pump-consumer',
}

// ES - Corporate
export const heatPumpCorporateES = {
  id: '5a8a06b6-ca98-4c59-a320-979345550e14',
  identifier: 'heat-pump-corporate',
}
