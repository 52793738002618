import { EventBus } from 'chimera/all/plugins/eventbus'
import { ConsentLevel } from 'chimera/all/plugins/eventbus/events'

const CONSENT_STATUS = 'consent_status'
const IS_EXPLICIT = 'explicit'

let options = {
  id: null,
}

export const ServiceIds = {
  USERCENTRICS_CMP: 'H1Vl5NidjWX',
  AB_SMARTLY: 'yDs2tC_qtLDpOR',
  DOUBLECLICK_AD: '9V8bg4D63',
  FACEBOOK_PIXEL: 'ko1w5PpFl',
  FACEBOOK_SOCIAL_PLUGIN: 'XYQZBUojc',
  GA_AUDIENCES: 'FRfzEaKQ2Fm4DR',
  GOOGLE_ADS: 'S1_9Vsuj-Q',
  GOOGLE_ANALYTICS: 'HkocEodjb7',
  GOOGLE_TAG_MANAGER: 'BJ59EidsWQ',
  GOOGLE_FONTS: 'HkPBYFofN',
  HOTJAR: 'S1kgcNo_j-m',
  HUBSPOT: 'ry0QcNodoWQ',
  LINKEDIN_ADS: 'rk-nqEj_o-m',
  LINKEDIN_INSIGHT_TAG: 'JQ2XQxIk',
  LINKEDIN_PLUGIN: 'Hkx754i_iWm',
  MICROSOFT_ADVERTISING_REMARKETING: 'dsS7z9Hv4',
  SENTRY: 'rH1vNPCFR',
}

export default ({ env }) => {
  options = {
    id: env.cookieConfigurationId,
  }

  if (!options.id) {
    this.$eventBus.emitErrorAppErrorEvent(
      new Error('Usercentrics ID is missing'),
    )
    return
  }

  installUsercentricsScript(options.id)
  initializeCookiebar()
}

/**
 * @param {Array} services
 * @returns {ConsentLevel}
 */
const determineConsentLevel = (services) => {
  const totalServices = services.length
  const consentServices = services.filter((service) => service.consent.status)
  const essentialServices = services.filter((service) => service.isEssential)

  let level

  if (totalServices === consentServices.length) {
    level = ConsentLevel.YES
  } else {
    const isPartiallyYes =
      consentServices.length > essentialServices.length &&
      consentServices.length < totalServices

    level = isPartiallyYes ? ConsentLevel.PARTIALLY_YES : ConsentLevel.NO
  }

  return level
}

const handleConsentEvent = (services) => {
  try {
    const services = getServicesBaseInfo()
    const level = determineConsentLevel(services)

    EventBus.emitConsentEvent({
      level,
      services,
    })
  } catch (error) {
    EventBus.emitErrorAppErrorEvent(error, 'handleConsentEvent')
  }
}

/**
 */
export const initializeCookiebar = () => {
  window.addEventListener('ucEvent', (event) => {
    if (event.detail.type !== IS_EXPLICIT) {
      return
    }

    switch (event.detail.action) {
      case 'onInitialPageLoad':
        if (event.detail.event === CONSENT_STATUS) {
          window.addEventListener('UC_UI_INITIALIZED', () => {
            handleConsentEvent()
          })
        }
        break
      case 'onAcceptAllServices':
      case 'onUpdateServices':
        handleConsentEvent()
        break
    }
  })
}

/**
 *
 * @param {Function} initializationFn
 * @param {Array} requiredServiceIds
 */
export function initializePluginWithConsent(
  initializationFn,
  requiredServiceIds = [],
) {
  if (!options.id) {
    initializationFn()
    return
  }

  EventBus.onConsentEvent(async (event) => {
    const consentGiven = await hasConsentForServices(requiredServiceIds)
    if (event.level === ConsentLevel.YES || consentGiven) {
      initializationFn()
    }
  })
}

/**
 * @param {string[]} serviceIds
 * @returns {Promise<boolean>}
 */
export async function hasConsentForServices(serviceIds) {
  if (!options.id) {
    return true
  }

  const isEventTriggered = await new Promise((resolve) => {
    const timeoutId = setTimeout(() => resolve(true), 2000)

    window.addEventListener(
      'ucEvent',
      () => {
        clearTimeout(timeoutId)
        resolve(true)
      },
      { once: true },
    )
  })

  if (!isEventTriggered || !window.UC_UI || !window.UC_UI.getServicesBaseInfo) {
    return false
  }

  const services = getServicesBaseInfo()
  const consentedServices = services.filter(
    (service) =>
      service.consent.status === true && serviceIds.includes(service.id),
  )

  return consentedServices.length === serviceIds.length
}

/**
 * @returns {[]}
 */
function getServicesBaseInfo() {
  return runUsercentricsFunction(
    'getServicesBaseInfo',
    'UC_UI.getServicesBaseInfo is not available',
  )
}

/**
 *
 */
export function openSecondLayer() {
  runUsercentricsFunction(
    'showSecondLayer',
    'UC_UI.showSecondLayer is not available',
  )
}

/**
 *
 */
export function showEmbeddings() {
  runUsercentricsFunction(
    'restartEmbeddings',
    'UC_UI.restartEmbeddings is not available',
  )
}

/**
 * @param {string} functionName
 * @param {string} errorMessage
 * @returns {*}
 */
function runUsercentricsFunction(functionName, errorMessage) {
  if (!window.UC_UI || !window.UC_UI[functionName]) {
    EventBus.emitErrorAppErrorEvent(new Error(errorMessage), functionName)
    return
  }

  return window.UC_UI[functionName]()
}

/**
 * @param {string} usercentricsId
 */
function installUsercentricsScript(usercentricsId) {
  const usercentricsScript = document.createElement('script')
  usercentricsScript.id = 'usercentrics-cmp'
  usercentricsScript.src = '//app.usercentrics.eu/browser-ui/latest/loader.js'
  usercentricsScript.setAttribute('data-settings-id', usercentricsId)
  usercentricsScript.setAttribute('async', 'true')
  document.head.appendChild(usercentricsScript)
}
