module.exports = {
  global: {
    'contact-us': 'Contactez-moi',
    back: 'Retourner',
    continue: 'Continuer',
    privacyPolicy: 'Déclaration de confidentialité',
    privacyPolicyLink: '/declaration-de-confidentialite',
    cookiePolicy: 'Cookies',
    cookiePolicyLink: '/cookies',
    termsAndConditions: 'Conditions générales',
    termsAndConditionsLink: '/conditions-generales',
    imprint: '@todo',
    imprintLink: '@todo',
    companyLink: 'https://www.bambelo.com/en/',
    receiveUpToSixQuotes:
      "Recevez jusqu'à 6 devis de professionnels de votre région et comparez.",
    requiredFields: '@todo',
    other: 'Autre',
    yes: '@todo',
    no: '@todo',
  },

  validation: {
    required: 'Ne pas les oubliez?',
    invalid: 'Quelque chose semble mal tourner.',
    blacklisted: 'Quelque chose semble mal tourner.',
    invalidLength: 'Le nombre de caractères semble incorrect.',
  },

  field: {
    postal: {
      label: 'Code postal',
      placeholder: 'Code postal',
      validation: {
        invalid: 'Votre code postal semble invalide.',
        required: "Vous avez oublié d'entrer votre code postal.",
        unknown: 'Nous ne connaissons pas votre code postal.',
        incorrectFormat: "Vous avez oublié d'entrer votre code postal.",
      },
    },

    comments: {
      label: 'Autres informations',
      placeholder: 'Autres informations',
      validation: {
        invalid: '@todo',
        required: 'Vous ne voulez vraiment rien demander à nos experts?',
      },
    },

    'address-city': {
      label: 'Ville',
      placeholder: 'Ville',
      validation: {
        invalid: 'Votre lieu de résidence semble incorrecte.',
        required: "Vous avez oublié d'entrer votre lieu de résidence.",
      },
    },

    'address-country-name': {
      label: 'Pays',
      placeholder: 'Pays',
      validation: {
        invalid: 'Votre pays semble incorrecte.',
        required: "Vous avez oublié d'entrer votre votre pays.",
      },
    },

    'address-floor-level': {
      label: 'Étage',
      placeholder: 'Étage',
      validation: {
        invalid: 'Votre étage semble incorrecte.',
        required: "Vous avez oublié d'entrer dans votre étage.",
      },
    },

    'address-number': {
      label: 'Numéro de maison',
      placeholder: 'Numéro de maison',
      validation: {
        invalid: 'Votre numéro de maison semble incorrect.',
        required: "Vous avez oublié d'entrer votre numéro de maison.",
      },
    },

    'address-property-type': {
      label: 'Type de logement',
      placeholder: 'Type de logement',
      validation: {
        invalid: 'Votre type de logement semble incorrect.',
        required: "Vous avez oublié d'entrer votre type de logement.",
      },
    },

    'address-street': {
      label: 'Adresse',
      placeholder: 'Adresse',
      validation: {
        invalid: 'Votre adresse semble incorrecte.',
        required: "Vous avez oublié d'entrer votre adresse.",
      },
    },

    'company-name': {
      label: 'Nom de l’entreprise',
      placeholder: 'Nom de l’entreprise',
      validation: {
        invalid: 'Le nom de votre entreprise semble correct.',
        required: "Vous avez oublié d'entrer le nom de votre entreprise.",
      },
    },

    'dormer-width': {
      label: '@todo',
      placeholder: '@todo',
      validation: {
        invalid: '@todo',
        required: '@todo',
      },
    },

    email: {
      label: 'Adresse e-mail',
      placeholder: 'Adresse e-mail',
      validation: {
        invalid: 'Votre adresse e-mail semble incorrecte.',
        required: "Vous avez oublié d'entrer votre adresse e-mail.",
      },
    },

    name: {
      label: 'Nom',
      placeholder: 'Nom',
      validation: {
        invalid: 'Votre nom semble incorrect.',
        required: "Vous avez oublié d'entrer votre nom.",
      },
    },

    phone: {
      label: 'Numéro de téléphone',
      placeholder: 'Numéro de téléphone',
      validation: {
        invalid: 'Votre numéro de téléphone semble incorrect.',
        required: "Vous avez oublié d'entrer votre numéro de téléphone.",
      },
    },

    message: {
      label: 'Votre message',
      placeholder: 'Votre message',
      validation: {
        invalid: '@todo',
        required: 'Vous avez oublié de saisir votre message.',
      },
    },

    'contact-name': {
      label: 'Personne à contacter',
      placeholder: 'Personne à contacter',
      validation: {
        invalid: '@todo',
        required: "Vous avez oublié d'entrer le nom de la personne de contact.",
      },
    },

    'estimated-garden-area': {
      label: '@todo',
      placeholder: '@todo',
      validation: {
        invalid: '@todo',
        required: '@todo',
      },
    },

    'move-preferred-date': {
      validation: {
        invalid: '@todo',
        required: '@todo',
      },
      flexibleDate: '@todo',
      specificDate: '@todo',
    },

    'period-of-construction': {
      options: {
        'before-1975': '@todo',
        'between-1975-and-1992': '@todo',
        'after-1992': '@todo',
      },
    },

    'type-of-heating': {
      options: {
        'floor-heating': '@todo',
        'floor-heating-and-radiators': '@todo',
        radiators: '@todo',
      },
    },

    'type-of-heat-pump': {
      options: {
        'air-air-heat-pump': '@todo',
        'air-water-heat-pump': '@todo',
        'ground-water-heat-pump': '@todo',
        'water-water-heat-pump': '@todo',
        'hybrid-heat-pump': '@todo',
        unknown: '@todo',
      },
    },

    'type-of-insulation': {
      options: {
        'roof-insulation': '@todo',
        'cavity-wall-insulation': '@todo',
        'floor-insulation': '@todo',
        'double-glazing': '@todo',
        'no-insulation': '@todo',
      },
    },

    'type-of-existing-radiator': {
      options: {
        'low-temperature-radiator': '@todo',
        'regular-radiator': '@todo',
        'low-temperature-radiator-description': '@todo',
        'regular-radiator-description': '@todo',
      },
    },

    'intends-to-replace-radiator': {
      options: {
        description: '@todo',
      },
    },

    'application-type': '@todo',
    'business-premises': '@todo',
    'agricultural-enterprise': '@todo',
    'large-business-requests': '@todo',
    'residential-building': '@todo',
    'type-of-assignment': '@todo',
    'house-battery': '@todo',
    'house-battery-and-solar-panels': '@todo',
    'estimated-annual-electricity-consumption': '@todo',
    'data-incompleted-error-message': '@todo',
  },

  'phone-validation': {
    title: 'Presque là !',
    subtitle:
      'Vogliamo confermare che il tuo telefono sia corretto in modo che i professionisti possano contattarti.',
    instructions:
      'Confirmez votre téléphone avec le code que nous vous avons envoyé au numéro {number} pour recevoir vos devis.',
    'send-sms': 'Renvoyer le SMS',
    'edit-number': 'Modifier le téléphone',
    validate: 'Valider le téléphone',
    'verify-result': {
      'error-message': 'Code invalide : réessayez.',
    },
    'result-codes': {
      'sms-is-not-supported':
        'Le numéro de téléphone fixe ne prend pas en charge les SMS',
      'max-attempts-reached': 'Vous avez atteint le maximum de 5 tentatives.',
      'rate-limit-triggered':
        "Cette limite de débit est déclenchée lorsque le cycle de vie de vérification (soumettre et vérifier) n'est pas terminé.",
      'too-many-concurrent-requests':
        'Trop de demandes simultanées de numéros de téléphone',
    },
  },

  privacyPolicy: {
    title: 'Déclaration de confidentialité',
    headDescription:
      "Déclaration de confidentialité à l'attention des visiteurs du site Web",
    introduction:
      "{concept} attache une grande importance à la protection de votre vie privée. C'est pourquoi nous voulons être ouverts et transparents quant au traitement de vos données à caractère personnel. La présente déclaration de confidentialité fournit donc des informations sur la manière dont vos données à caractère personnel sont traitées et protégées. Cette politique de confidentialité s'applique à l'utilisation du site web et des services de {concept} accessibles via ce site web.",

    section1Title: 'Qui traite vos données à caractère personnel ?',
    section1Body1:
      "La société néerlandaise Bambelo B.V. traite les données à caractère personnel que vous nous fournissez via l'un de nos sites web et est responsable de vos données à caractère personnel en vertu de la législation applicable en matière de protection des données. Nos coordonnées sont les suivantes :",
    companyAddressStreet: 'Prinsenkade 5B',
    companyZipcode: '4811 VB',
    companyAddressCity: 'Breda Pays-Bas',
    companyChamberOfCommerce: 'Chambre de Commerce n°: 20111350',
    section1Body2:
      "Si vous utilisez l'un de nos sites web pour demander un devis, nous transmettrons vos données à nos partenaires affiliés qui répondent aux critères que vous avez spécifiés. Ils traitent vos données conformément à leur propre politique de confidentialité.  Sur la base de ces informations, ces entreprises vous contacteront par courriel ou par téléphone pour vous informer de leurs services et de leurs tarifs. Une seule demande via {concept} entraînera donc le stockage de données chez {concept} elle-même et chez les entreprises liées à votre demande. Sur la base des lois et règlements et d'un accord conclu entre Bambelo B.V. et les organisations auxquelles Bambelo fournit votre demande, les organisations qui reçoivent vos données doivent également respecter les dispositions de l'accord conclu avec elles et le Règlement général sur la Protection des Données (RGPD). Vous trouverez les coordonnées de ces parties dans les devis qu'elles vous adressent. Si une entreprise décide de ne pas accepter la demande de devis, elle sera obligée de détruire les données reçues.",

    section2Title: 'Où stockons-nous vos données ?',
    section2Body1:
      "Les données que nous recueillons auprès de vous seront en principe stockées dans l'Espace économique européen (« EEE »). Si les données sont transférées par nous et traitées dans un pays situé en dehors de l'EEE, cela se fera conformément aux lois applicables, normalement sur la base de clauses contractuelles types et, si nécessaire, de mesures supplémentaires.",

    section3Title:
      'Quels types de données à caractère personnel traitons-nous et sur quelle base ?',
    section3Body1:
      'Nous traitons les catégories suivantes de données à caractère personnel :',
    section3List1Item1:
      "Coordonnées, telles que le nom, l'adresse, l'adresse électronique et le numéro de téléphone",
    section3List1Item2:
      'Des données supplémentaires pertinentes pour la demande, notamment :',
    section3List1Item2Sub1: 'Catégorie',
    section3List1Item2Sub2: 'Type de demande',
    section3List1Item2Sub3: 'Fourchette de prix',
    section3List1Item2Sub4: 'Type de logement',
    section3List1Item2Sub5:
      'Autres informations que vous communiquez vous-même',
    section3Body2:
      'Nous traitons les données susmentionnées sur la base des motifs juridiques suivants :',
    section3List2Item1:
      "Si vous demandez des devis via notre site web : afin d'exécuter un contrat conclu avec vous, ou sur la base de votre consentement",
    section3List2Item2:
      'Si vous posez une question (via notre site web) : sur la base de votre consentement',
    section3List2Item3:
      "Dans d'autres cas : pour la protection des intérêts légitimes que nous avons dans le cadre de nos opérations commerciales, comme la gestion de la relation client, l'amélioration de nos services, le maintien de la sécurité et de l'accessibilité du site web, et le maintien du contact avec nos clients. En l’occurrence, nous mettons toujours en balance ces intérêts et votre vie privée et ne traitons que les données nécessaires pour atteindre les objectifs définis.",

    section4Title: 'Combien de temps conservons-nous vos données ?',
    section4Body:
      "Nous conserverons vos données pendant une période allant jusqu'à 7 ans au maximum. Nous le faisons d'une part pour respecter notre période de conservation légale et d'autre part pour pouvoir observer les tendances et améliorer nos services. Si vous indiquez que vous ne souhaitez plus faire usage de nos services ou que vous voulez que les données soient supprimées, nous évaluerons si cette période de conservation peut être raccourcie et quelles données nous pouvons supprimer.",

    section5Title: 'Qui a accès à vos données ?',
    section5Body:
      'Les données à caractère personnel que vous fournissez sont utilisées pour assurer le bon fonctionnement du service offert par {concept} . {concept} fournit uniquement les données à caractère personnel nécessaires aux partenaires affiliés afin de vous proposer une offre adaptée à votre demande.',

    section6Title: 'À quoi servent vos données à caractère personnel ?',
    section6Body:
      "Nous utilisons vos données principalement dans le but de demander des devis pour le produit ou le service que vous recherchez. Nous les utilisons également pour évaluer, développer et améliorer nos services, produits et systèmes. Il s'agit notamment d'analyses visant à rendre nos services plus conviviaux, par exemple en modifiant l'interface utilisateur pour simplifier le flux d'informations ou en mettant en évidence les fonctions qui sont souvent utilisées, et en améliorant les systèmes informatiques pour renforcer la sécurité. À cette fin, nous n'analyserons pas vos données à un niveau individuel. Nous utilisons également ces données pour développer de nouveaux services et améliorer notre gamme de produits. La fourniture (et le traitement) de ces données est une condition préalable nécessaire à l'exécution du contrat ou de votre demande de recevoir des devis. Si vous ne fournissez pas ces données, nous ne serons pas en mesure de vous servir, ou vous servirons mal.",

    section7Title: 'Quels sont vos droits ?',
    section7Right1Title: "Droit d'accès :",
    section7Right1:
      '{title} Vous avez le droit de demander à tout moment des informations sur les données à caractère personnel que nous détenons à votre sujet.',
    section7Right2Title: 'Droit à la portabilité :',
    section7Right2:
      "{title} Si {concept} traite vos données à caractère personnel de manière automatisée sur la base de votre consentement ou sur la base d'un accord, vous avez le droit de recevoir une copie de vos données dans un format structuré, couramment utilisé et lisible par machine, transféré à vous ou à une autre partie. Cela ne concerne que les données à caractère personnel que vous nous avez fournies.",
    section7Right3Title: 'Droit de rectification :',
    section7Right3:
      '{title} Vous avez le droit de demander la rectification de vos données à caractère personnel si elles sont incorrectes, y compris le droit de faire compléter des données à caractère personnel incomplètes.',
    section7Right4Title: "Droit à l'oubli :",
    section7Right4:
      '{title} Vous avez le droit de nous demander de supprimer les données à caractère personnel traitées par {concept} .',
    section7Right5Title:
      "Droit de restreindre le traitement et de s'opposer au traitement :",
    section7Right5:
      "{title} Vous pouvez demander que le traitement des données soit (temporairement) limité, par exemple afin de vérifier l'exactitude des données. Vous avez également le droit de vous opposer au traitement de vos données à caractère personnel. Si vous vous opposez au traitement à des fins de marketing direct, les données à caractère personnel ne seront plus traitées à ces fins.",
    section7Right6Title: 'Droit de retirer le consentement :',
    section7Right6:
      "{title} Dans la mesure où nous traitons les données sur la base de votre consentement, vous avez le droit de le retirer à tout moment. Cela n'affecte pas la légalité du traitement préalable à la révocation.",
    section7Body:
      "Pour toutes les demandes ci-dessus, vous recevrez une réponse à votre demande dans un délai d'un mois. En fonction de la complexité des demandes et du nombre de demandes, ce délai peut être prolongé de deux mois supplémentaires si nécessaire, auquel cas nous vous en informerons dans le mois suivant la réception de votre demande.",

    section8Title: 'Comment pouvez-vous exercer vos droits ?',
    section8Body:
      "Nous prenons la protection des données très au sérieux. C'est pourquoi nous disposons d'une équipe de service à la clientèle spécialisée qui répond à vos questions concernant les droits susmentionnés et où vous pouvez déposer une plainte. Vous pouvez toujours joindre notre personnel à l'adresse {email}.",

    section9Title: 'Coopération dans les enquêtes fiscales et criminelles',
    section9Body:
      "Si nécessaire, nous pouvons être obligés par la loi de partager vos données dans le cadre d'enquêtes gouvernementales fiscales et criminelles. Dans ce cas, nous sommes contraints de partager vos données, mais nous nous y opposerons dans les limites de la loi.",

    section10Title: 'Délégué à la protection des données',
    section10Body:
      "Nous avons nommé un délégué à la protection des données (DPD) afin de nous assurer que nous traitons en permanence vos données à caractère personnel de manière ouverte, précise et légale. Vous pouvez contacter notre DPD à l'adresse {email} avec DPD comme sujet.",

    section11Title:
      "Le droit de déposer une plainte auprès d'une autorité de surveillance",
    section11Body:
      "Si vous pensez que {concept} ne traite pas correctement vos données à caractère personnel, vous pouvez nous contacter. Vous avez également le droit d'introduire une plainte auprès de l'Autorité néerlandaise pour la protection des données à caractère personnel (Autoriteit Persoonsgegevens) contre ces traitements. Vous pouvez contacter l'Autoriteit Persoonsgegevens via ce {link}",
    section11BodyLinkText: 'lien.',
    section11BodyLink:
      'https://www.autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap',

    section12Title: 'Mises à jour de notre déclaration de confidentialité',
    section12Body:
      "Il se peut que notre déclaration de confidentialité doive être mise à jour. La dernière version de la déclaration de confidentialité est toujours disponible sur notre site web. Nous vous informerons de toute modification importante de la déclaration de confidentialité, par exemple en ce qui concerne la finalité de l'utilisation de vos données à caractère personnel, l'identité du Responsable du traitement ou vos droits.",

    version: 'Version 4.1 – 23 février 2022',
  },

  cookiePolicy: {
    headTitle: 'Politique en matière de cookies',
    title: 'Les cookies',
    whatIsCookies: {
      title: 'Que sont les cookies ?',
      body: 'Les cookies sont de petites informations que votre navigateur stocke sur votre ordinateur lorsque vous visitez notre site Web et qui peuvent être appelées lors de visites ultérieures. Nous utilisons des cookies sur ce site Web.',
    },
    whatIsCookieFunction: {
      title: 'Quelle est la fonction de ces cookies ?',
      firstParagraph:
        'Nous utilisons des cookies pour nous assurer que vous ne recevez pas ou ne saisissez pas à plusieurs reprises les mêmes informations lorsque vous visitez notre site Web. Il existe différents types de cookies. En pièce jointe, vous trouverez un aperçu de tous les cookies utilisés qui sont affichés, qui place les cookies, le nom du cookie, le but du placement du cookie, la période pendant laquelle le cookie est stocké et les conséquences de ne pas accepter le cookie.',
      secondParagraph:
        "Vous pouvez désactiver le stockage des cookies sur votre ordinateur via votre navigateur. Vous pouvez également être averti lorsque vous recevez un cookie. Des instructions sur la façon d'ajuster les paramètres du navigateur peuvent être trouvées sous « aide » dans la barre d'outils de la plupart des navigateurs. Bien que vous, en tant que visiteur du site, puissiez toujours visiter le site, il est possible que si les cookies sont refusés, certains services ou éléments du site ne fonctionnent pas de manière optimale. La désactivation des cookies limite l'utilisation de notre site et de nos services.",
      thirdParagraph:
        "Certains cookies de suivi sont placés par des tiers qui, entre autres, vous affichent des publicités via notre site Web. Vous pouvez supprimer ces cookies de manière centralisée via {link} afin qu'ils ne soient pas replacés sur un site Web tiers.",
      url: 'https://www.youronlinechoices.com/fr/controler-ses-cookies',
    },
    whoIAm: {
      title: 'Les cookies savent-ils qui je suis ?',
      body: "Non. Un cookie ne connaît pas votre nom, votre adresse, votre âge et d'autres informations personnelles. Ils se souviennent uniquement de vos préférences et de vos intérêts en fonction de votre comportement de navigation.",
    },
    listTitle: 'Liste des cookies',
    adjustPreferences: 'Ajuster les préférences en matière de cookies',
    version: 'Version',
  },

  termsAndConditions: {
    title: 'Conditions générales',
    headDescription: 'Conditions générales',
  },
}
