/**
 * Tracks a purchase as a Meta Service Goal
 *
 * @param {object} transaction
 * @param {string} transaction.id
 * @param {number} transaction.revenueMade
 * @param {object} transaction.service
 * @example {
 *   id: '8303de5c-c2bc-4329-be41-7f82572b863b',
 *   revenueMade: 15.50,
 *   defaultPrice: 5,
 *   service: {
 *     id: '492e346e-80ca-42bc-b7ca-3fd1eb37f45c',
 *     identifier: 'branch-category-consumer'
 *   }
 * }
 */
export function trackPurchase({ id, revenueMade, service }) {
  if (id === undefined || revenueMade === undefined || service === undefined) {
    const serviceIdentifier =
      !service || !service.identifier
        ? 'unknown-service-identifier'
        : service.identifier
    throw new TypeError(
      `Received transaction event with id: ${id}, revenueMade: ${revenueMade} service: ${serviceIdentifier}`,
    )
  }

  window.fbq(
    'track',
    'Purchase',
    {
      value: revenueMade,
      currency: 'EUR',
    },
    { eventID: id },
  )
}
