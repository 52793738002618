import Vue from 'vue'
import MetaTracking from 'chimera/all/plugins/tracking/meta'
import { EventBus } from 'chimera/all/plugins/eventbus'

export default ({ env, store }) => {
  Vue.use(MetaTracking, {
    pixels: getPixels(env),
    store,
  })
}

/**
 * Get all defined fb pixels
 *
 * @param {object} env
 * @returns {Array<string>}
 */
export function getPixels(env) {
  let pixels = []

  // Bambelo B.V. pixels (new)
  pixels = addPixelToPixels('metaConceptPixel', env.metaConceptPixel, pixels)
  pixels = addPixelToPixels('metaRollupPixel', env.metaRollupPixel, pixels)

  // Yonego Agency B.V. pixels (old)
  // TODO: https://bambelo.atlassian.net/browse/PD-4456
  pixels = addPixelToPixels('metaRollupId', env.metaRollupId, pixels)
  pixels = addPixelToPixels('metaPixelId', env.metaPixelId, pixels, false)

  return pixels
}

/**
 * Add values to passed options object.
 *
 * @param {string} name
 * @param {string} value
 * @param {Array} pixels
 * @param {boolean} required
 * @returns {Array}
 */
function addPixelToPixels(name, value, pixels, required = true) {
  if (!value || value === '') {
    if (required) {
      EventBus.emitWarningAppErrorEvent(
        new Error(`Required Meta Pixel ${name} has not been defined.`),
        {
          name,
          value,
          required,
        },
      )
    }

    return pixels
  }

  pixels.push(value)
  return pixels
}
