/**
 * @param {object}  payload
 * @param {string}  payload.id
 * @param {string}  payload.revenueMade
 */
export function trackConversionGoal({ id, revenueMade }) {
  window.uetq = window.uetq || []
  window.uetq.push('event', 'lead', {
    event_category: 'transaction',
    revenue_value: revenueMade,
    currency: 'EUR',
  })
}

/**
 * Note that we remove the pathName (f.e. /p/aa/) from the page path so the data we gather during
 * running split url tests is the same as it is once it becomes the new control.
 *
 * @param {object} event
 * @param {object} event.scope
 */
export function trackPageView({ scope }) {
  window.uetq = window.uetq || []
  window.uetq.push('event', 'page_view', {
    page_path: scope.pathname.replace(process.env.pathName, '/') + scope.search,
  })
}

/**
 * @param {object} fields
 * @param fields.email
 * @param fields.phone
 */
export function trackEnhancedConversionGoal({ email: em, phone: ph }) {
  window.uetq = window.uetq || []
  window.uetq.push('set', {
    pid: {
      em,
      ph,
    },
  })
}
