/**
 * Track service selection as Meta ViewContent
 *
 * @param {object} event
 * @param {string} event.service
 */
export function trackServiceSelection({ service }) {
  window.fbq('track', 'ViewContent', {
    content_category: service.identifier,
  })
}
