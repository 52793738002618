import { EventBus } from 'chimera/all/plugins/eventbus'
import Vue from 'vue'

export const referralObjectKey = 'referral'
export const referralIdObjectKey = 'referral-id'
export const referralKeyObjectKey = 'referral-key'

export const getDefaultState = () => {
  return {}
}

export const state = getDefaultState

export const mutations = {
  /**
   * Update data to store.
   *
   * @param {object} state
   * @param {object} submitData
   */
  update(state, submitData) {
    const { key, value } = submitData

    if (key === undefined) {
      EventBus.emitWarningAppErrorEvent(
        new Error(
          'referral/mutations.update: mutation called with undefined key',
        ),
        { key, value },
      )
      return
    }

    const data = {}
    data[key] = value
    Object.assign(state, data)
  },

  /**
   * Stores referral from an object with query parameters
   *
   * @param {object} state
   * @param {object} filteredParamsObject
   */
  setReferrals(state, filteredParamsObject) {
    Object.assign(state, filteredParamsObject)
  },

  /**
   * Reset state
   *
   * @param {object} state
   * @param {object} newState
   */
  reset(state, newState = getDefaultState()) {
    newState.referral = state.referral

    Object.keys(state).forEach((key) => Vue.delete(state, key))

    Object.assign(state, newState)
  },
}

export const actions = {
  /**
   * Action to store referrals from query parameters
   * Filters the relevant params to an object and calls setReferrals mutation
   *
   * @param {object}    context
   * @param {Function}  context.commit
   * @param {Function}  context.dispatch
   * @param {object}    queryParams
   * @param {string}    queryParams.gclid
   * @param {string}    queryParams.wbraid
   * @param {string}    queryParams.gbraid
   * @param {string}    queryParams.fbclid
   * @param {string}    queryParams.msclkid
   * @param {string}    queryParams.leadr_source
   */
  setReferrals({ commit, dispatch }, queryParams) {
    const leadrSource = queryParams.leadr_source
    if (leadrSource === undefined) {
      throw new TypeError('leadr_source is undefined')
    }

    // Reset referral and lead referral data
    commit('reset')

    // Set referral
    const referralObject = {}
    referralObject[referralObjectKey] = leadrSource

    // The preferred order of referral keys
    const referralKeyOrderPreference = [
      'gclid',
      'wbraid',
      'gbraid',
      'fbclid',
      'msclkid',
    ]
    for (const referralKey of referralKeyOrderPreference) {
      const isReferralKeyFoundInQueryParams = referralKey in queryParams

      // When found we can exit the loop, we have found our referral-id and referral-key
      if (isReferralKeyFoundInQueryParams) {
        referralObject[referralKeyObjectKey] = referralKey
        referralObject[referralIdObjectKey] = queryParams[referralKey]
        break
      }
    }

    commit('setReferrals', replaceArrayWithFirstValue(referralObject))
  },
}

/**
 * When referral or referral-id happens to be an array, the first entry will be used instead.
 *
 * @param {object} referralObject
 * @returns {object}
 */
export function replaceArrayWithFirstValue(referralObject) {
  for (const [key, value] of Object.entries(referralObject)) {
    referralObject[key] = Array.isArray(value) ? value[0] : value
  }

  return referralObject
}

export default {
  state,
  actions,
  mutations,
  cleanReferrals: replaceArrayWithFirstValue,
}
