import { bindOnceOn } from 'chimera/all/plugins/eventbus/events/bind'
import { emit } from './emit'

/**
 * Happens when an enhanced conversion takes place, like completing
 * a form (contact, newsletter signup etc.).
 *
 * @type {string}
 */
export const enhancedConversionEvent = 'eb_enhancedConversion'

/**
 * @param {object} field
 */
export function emitEnhancedConversionEvent(field) {
  emit(enhancedConversionEvent, field)
}

/**
 * Perform callback function when an enhanced conversion event happens.
 *
 * @param callback
 */
export function onceOnEnhancedConversionEvent(callback) {
  bindOnceOn(enhancedConversionEvent, callback)
}
