// NL
export const accountancyConsumerNL = {
  id: '774b83d1-7321-45c0-9d97-fb9723a14c8b',
  identifier: 'accountancy-consumer',
}

export const accountancyCorporateNL = {
  id: '5390a68a-6e3a-45a0-bb0f-ce0bc1b72a15',
  identifier: 'accountancy-corporate',
}
