// NL - Consumer
export const airConditioningInstallationConsumerNL = {
  id: '27312f18-9760-4480-9c68-101265a2c480',
  identifier: 'air-conditioning-installation-consumer',
}

export const airConditioningRepairConsumerNL = {
  id: '646242c4-3f28-4af3-a379-532c6760f010',
  identifier: 'air-conditioning-repair-consumer',
}

// NL - Corporate
export const airConditioningInstallationCorporateNL = {
  id: 'cb8dc1a1-a442-4f01-8147-d6988d31542f',
  identifier: 'air-conditioning-installation-corporate',
}

export const airConditioningRepairCorporateNL = {
  id: '373603f8-874b-495d-8642-8696b98f390c',
  identifier: 'air-conditioning-repair-corporate',
}
