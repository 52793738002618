import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * An unexpected javascript error event occurs in our application.
 *
 * @type {string}
 * @example {
 *   field: 'postal',
 *   errors: [
 *     'invalid_regex'
 *   ]
 * }
 */
export const formErrorEvent = 'eb_formError'

/**
 * @param {string} field
 * @param {string[]} errors
 */
export function emitFormErrorEvent(field, errors) {
  emitFormErrorEventInScope(getEventScope(), field, errors)
}

/**
 * @param {object} scope
 * @param {string} field
 * @param {string[]} errors
 */
export function emitFormErrorEventInScope(scope, field, errors) {
  emit(formErrorEvent, {
    field,
    errors,
    scope,
  })
}

/**
 * @param {Function} callback
 */
export function onFormErrorEvent(callback) {
  bindOn(formErrorEvent, callback)
}
