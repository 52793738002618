import { EventBus } from 'chimera/all/plugins/eventbus'
import Vue from 'vue'

export const getDefaultState = () => {
  return {
    data: {
      label: {
        origin: undefined,
        value: process.env.label,
      },
    },
    result: {
      id: '',
      revenueMade: 0,
      defaultPrice: 0,
    },
  }
}

export const state = getDefaultState

export const getters = {
  /**
   * Getter for lead data
   *
   * @param {object} state
   * @returns {Function}
   */
  getData: (state) => {
    return (key = undefined, fallback = undefined) => {
      if (!state.data) {
        EventBus.emitWarningAppErrorEvent(
          new Error(
            'lead/mutations.getData: data with key is missing from state',
          ),
          { key },
        )
        return fallback
      }

      if (undefined === key) {
        return state.data
      }

      return (state.data[key] && state.data[key].value) || fallback
    }
  },

  /**
   * Getter for lead result
   *
   * @param {object} state
   * @returns {Function}
   */
  getResult: (state) => {
    return (key = undefined, fallback = undefined) => {
      if (!state.result) {
        EventBus.emitWarningAppErrorEvent(
          new Error(
            'lead/mutations.getResult: result with key is missing from state',
          ),
          { key },
        )
        return fallback
      }

      if (undefined === key) {
        return state.result
      }

      return state.result[key] || fallback
    }
  },
}

export const mutations = {
  /**
   * Update data to store.
   *
   * @param {object} state
   * @param {object} submitData
   * @param {string} submitData.key
   * @param {any} submitData.value
   * @param {string|null} submitData.origin
   */
  update(state, { key, value, origin }) {
    if (key === undefined) {
      EventBus.emitWarningAppErrorEvent(
        new Error('lead/mutations.update: mutation called with undefined key'),
        { key, value, origin },
      )
      return
    }

    const data = {}
    data[key] = {
      origin,
      value,
    }

    // Update data reactively
    const mergedData = Object.assign({}, state.data, data)
    Vue.set(state, 'data', mergedData)
  },

  /**
   * Remove key from store.
   *
   * @param {object} state
   * @param {string} key
   */
  remove(state, key) {
    Vue.delete(state.data, key)
  },

  /**
   * Update result in state
   *
   * @param {object} state
   * @param {object} leadResult
   */
  updateResult(state, leadResult) {
    const mergedResult = Object.assign({}, state.result, leadResult)
    Vue.set(state, 'result', mergedResult)
  },

  /**
   * Reset state
   *
   * @param {object} state
   * @param {object} newState
   */
  reset(state, newState = getDefaultState()) {
    Object.assign(state, newState)
  },
}

export const actions = {
  /**
   * Add data to store
   *
   * @param {Function} commit
   * @param {object} data
   */
  add({ commit }, data) {
    commit('update', data)
  },

  /**
   * @param {Function} commit
   * @param {string} key
   */
  rm({ commit }, key) {
    commit('remove', key)
  },

  /**
   * Update result to store
   *
   * @param {Function} commit
   * @param {object} leadResult
   */
  updateResult({ commit }, leadResult) {
    commit('updateResult', leadResult)
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
